<template>
  <section class="about" style="background:#f8f8f8;">
    <v-img
      v-if="info.banners[0]"
      :src="info.banners[0].img"
      class="top-banner"
      >
        <v-layout
          align-center
          fill-height
          justify-center
          style="position: relative; text-align: center;"
        >
          <v-flex style="position: absolute; bottom: 40px;" class="ctk-about-submenu">
            <template>
              <v-tabs
                v-model="tabSubMenu"
                light
                grow
              >
                <v-tab
                  v-for="(menu, k) in subMenu"
                  :key="k"
                  :to="'/service/' + menu.key"
                >
                  {{menu.name}}
                </v-tab>
              </v-tabs>
            </template>
          </v-flex>
        </v-layout>
    </v-img>
    <v-container  grid-list-md text-center class="ctk-div" style="padding-top:60px !important;">
      <v-container
        fluid
        grid-list-sm
      >
        <v-layout wrap>
          <v-flex class="text-left">
            <h3 style="color:#333; font-size:32px;margin-bottom:20px;">{{this.$t('service.qaTitle')}}：</h3>
            <template v-for="(item, k) in qa">
              <v-expansion-panels class="ctk-qa" :key="k">
                <v-expansion-panel
                  style="margin-bottom: 30px;"
                >
                  <v-expansion-panel-header>
                    <div>
                      <v-chip
                        class="mr-4"
                        color="primary"
                        label
                      >Q
                      </v-chip>
                      {{item.title}}
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <template v-for="(a, i) in item.answer">
                      <div :key="i" style="margin-bottom:10px;">
                        <v-chip
                          class="mr-4"
                          label
                          color="grey darken-3"
                          style="color:#FFF;"
                          v-if="i==0"
                        >A
                        </v-chip>
                        {{a.content}}
                      </div>
                    </template>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>
          </v-flex>
        </v-layout>
      </v-container>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 5,
    tabSubMenu: null,
    subMenu: null,
    qa: null
  }),
  created () {
    document.querySelector('#menu_service').classList.add('v-btn--active')
    this.tabSubMenu = 2
    this.getPageData()
    this.getQa()
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        let locale = localStorage.getItem('locale')
        if (!locale) {
          locale = 'cn'
        }
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      let locale = localStorage.getItem('locale')
      if (!locale) {
        locale = 'cn'
      }
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    }
  },
  methods: {
    getPageData () {
      this.subMenu = this.$t('service.subMenu')
    },
    getQa () {
      this.https.get('qa', { id: this.$route.params.id }).then(response => {
        if (response.code === 0) {
          this.qa = response.data
        }
      })
    }
  }
}
</script>
<style>
</style>
